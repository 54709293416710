:root {
  --background-color: rgb(250, 250, 250);
  --translucent-background-color: rgba(240, 240, 240, 0.5);
  --contrast-color-1: rgb(220, 220, 220);
  --contrast-color-2: rgb(200, 200, 200);
  --contrast-color-3: #333;
  --contrast-color-4: #343c54;
  --contrast-color-5: #111;
  --accent-color: #1652f0;
  --small-font: 0.85rem;
  --chart-color: #99559b;
  --row-color: #f3e4f5;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #111111;
    --translucent-background-color: rgba(17, 17, 17, 0.5);
    --contrast-color-1: #222222;
    --contrast-color-2: #333333;
    --contrast-color-3: #999999;
    --contrast-color-4: #c0c0c0;
    --contrast-color-5: #fafafa;
    --accent-color: #1f00ff;
    --small-font: 0.85rem;
    --chart-color: #d6b6d7;
    --row-color: #171722;
  }
}

html {
  background-color: var(--background-color);
  line-height: 1.5;
}

body {
  margin: 0;
  overflow-y: scroll;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  padding: 8px 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--contrast-color-5);
  margin: auto;
  max-width: 1100px;
}

svg {
  z-index: 1;
}

label {
  font-size: var(--small-font);
}

header {
  background-color: var(--contrast-color-1);
  border-bottom: solid 1px var(--contrast-color-2);
  margin-bottom: 16px;
}

footer {
  background-color: var(--contrast-color-1);
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 16px;
}

.Footer_row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 8px;
}

.Footer_row button {
  color: var(--contrast-color-5);
  margin: 0;
  padding: 0;
}

a {
  color: var(--contrast-color-5);
}

.secondary-link {
  color: var(--contrast-color-3);
}

button:not(.LinkButton) {
  display: inline-flex;
  align-items: center;
  column-gap: 4px;
  margin: 0px;
  min-width: 40px;
  min-height: 32px;
  background: var(--contrast-color-1);
  color: var(--contrast-color-5);
  border: solid 1px var(--contrast-color-2);
  cursor: pointer;
  padding: 8px;
  font-size: var(--small-font);
  transition: background 0.2s, border 0.2s;
}

button:not(.LinkButton):hover,
button:not(.LinkButton):disabled,
button:not(.LinkButton):active {
  border: solid 1px var(--contrast-color-3);
  background: var(--contrast-color-2);
  cursor: unset;
}

select {
  background-color: var(--background-color);
  border: solid 1px var(--contrast-color-2);
  color: var(--contrast-color-3);
  border-radius: 0px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.3em 3.5em 0.3em 1em;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      var(--contrast-color-3) 50%
    ),
    linear-gradient(135deg, var(--contrast-color-3) 50%, transparent 50%),
    linear-gradient(to right, var(--contrast-color-2), var(--contrast-color-2));
  background-position: calc(100% - 20px) calc(0.9em + 1px),
    calc(100% - 15px) calc(0.9em + 1px), calc(100% - 2.5em) 0em;
  background-size: 5px 5px, 5px 5px, 1px 34px;
  background-repeat: no-repeat;
}

select:hover {
  background-color: var(--contrast-color-1);
  border: solid 1px var(--contrast-color-2);
  color: var(--contrast-color-4);
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      var(--contrast-color-3) 50%
    ),
    linear-gradient(135deg, var(--contrast-color-3) 50%, transparent 50%),
    linear-gradient(to right, var(--contrast-color-2), var(--contrast-color-2));
}

@media only screen and (max-width: 800px) {
  select {
    width: 100%;
  }
}

table {
  width: 100%;
  margin: auto;
}

caption {
  caption-side: bottom;
  text-align: right;
  color: var(--contrast-color-3);
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 0.8rem;
}

table,
th,
td {
  border-collapse: collapse;
  white-space: nowrap;
}

td,
th {
  padding: 4px;
}

td {
  padding: 8px 4px;
}

th {
  font-weight: normal;
  color: var(--contrast-color-3);
  text-align: left;
  width: 150px;
  min-width: 110px;
  max-width: 150px;
}

tbody tr:nth-child(odd) {
  background: var(--row-color);
}

h1,
h2,
p {
  margin: 0;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 1.1rem;
  color: var(--contrast-color-3);
  background: var(--contrast-color-2);
  margin-top: 16px;
  padding: 6px;
  text-align: center;
}

h1 {
  color: var(--contrast-color-5);
  font-size: 2rem;
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
}

h1 img {
  width: 15px;
}

* {
  box-sizing: border-box;
  font-variant-numeric: tabular-nums;
}

@media only screen and (max-width: 800px) {
  .desktop-only {
    display: none;
  }
}

.mauto {
  margin: auto;
}

.ma0 {
  margin: 0;
}

.mb16 {
  margin-bottom: 16px;
}

.mb32 {
  margin-bottom: 32px;
}

.ml16 {
  margin-left: 16px;
}

.ml8 {
  margin-left: 8px;
}

.mr4 {
  margin-right: 4px;
}

.mr6 {
  margin-right: 6px;
}

.ml4 {
  margin-left: 4px;
}

.ml6 {
  margin-left: 6px;
}

.p8 {
  padding: 8px;
}

.p16 {
  padding: 16px;
}

.pt8 {
  padding-top: 8px;
}

.my8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mt8 {
  margin-top: 8px;
}

.mt16 {
  margin-top: 16px;
}

.mt32 {
  margin-top: 32px;
}

.m16 {
  margin: 16px;
}

.my16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.p16 {
  padding: 16px;
}

.p32 {
  padding: 32px;
}

.small-font {
  font-size: var(--small-font);
}

.flex {
  display: flex;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-column-gap-6 {
  column-gap: 6px;
}

.space-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: end;
}

.self-start {
  align-items: self-start;
}

.align-items-center {
  align-items: center;
}

.fade-in {
  animation: fadein 0.3s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.text-center {
  text-align: center;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.non-select * {
  user-select: none;
  -webkit-user-select: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.attribute {
  color: var(--contrast-color-3);
}

.accent {
  color: var(--accent-color);
}

.selected {
  background: var(--accent-color);
}

.active-border {
  border: solid 2px var(--accent-color);
}

.ActiveLine {
  height: 100%;
  width: 1px;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  touch-action: none;
  border-left: solid 1px rgba(76, 216, 228, 1);
}

.ActiveCircle {
  position: absolute;
  border-radius: 50%;
  z-index: 2;
  pointer-events: none;
  touch-action: none;
  background: rgba(76, 216, 228, 0.5);
  border: solid 1px rgba(76, 216, 228, 1);
}

.HorizontalGridLine {
  width: 100%;
  height: 1px;
  position: absolute;
  border-top: solid 1px var(--contrast-color-1);
}

.VerticalGridLine {
  height: 100%;
  width: 1px;
  position: absolute;
  border-left: solid 1px var(--contrast-color-1);
}

.Label {
  position: absolute;
  padding: 1px 3px;
  margin-top: 1px;
  border-radius: 0 3px 3px 0;
  pointer-events: none;
  touch-action: none;
  background: var(--translucent-background-color);
}

.Title {
  background: #000;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
}

.LinkButton {
  background: none;
  border: none;
  padding: none;
  margin: none;
  text-decoration: underline;
  font-size: 1rem;
  font-weight: normal;
  color: rgba(76, 216, 228, 1);
  cursor: pointer;
}

.ClickableHeader {
  cursor: pointer;
  color: var(--contrast-color-3);
  text-decoration: none;
  min-width: 0px;
  position: relative;
  display: inline;
  transition: color 0.1s;
}

.ClickableHeader:hover {
  color: var(--contrast-color-5);
}

.ClickableHeader_sort {
  position: absolute;
  right: -11px;
  color: var(--contrast-color-4);
  font-size: 12px;
  transition: transform 0.2s;
}

.PageTable {
  width: 100%;
  text-align: left;
}

.PageTable thead th {
  background: var(--background-color);
  color: var(--contrast-color-3);
}

.Legend {
  margin-top: 8px;
  margin-left: 8px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
}

.Legend_date {
  display: block;
  min-width: 110px;
}

.Legend_value {
  display: block;
  min-width: 60px;
}

.AavescanProAdvert {
  width: 100%;
  text-align: center;
  padding: 16px;
  border: solid 1px rgba(76, 216, 228, 0.2);
  background: rgba(76, 216, 228, 0.1);
}

.AavescanProAdvert a {
  color: rgba(76, 216, 228, 1);
  text-decoration: none;
}
.AavescanProAdvert:hover a {
  text-decoration: underline;
}
